import {MCMError} from '../models/error.model'
import {StackFrame} from 'stacktrace-js'
import {RecordExceptionOptions} from '@capacitor-firebase/crashlytics'

export class ErrorBuilder {
  private readonly _error: MCMError

  constructor(error: Error) {
    this._error = new MCMError(error.message)
    this.setName(error.name)
    this.setStack(error.stack)
  }

  setName(name: string): ErrorBuilder {
    this._error.name = name
    return this
  }

  setMessage(message: string): ErrorBuilder {
    this._error.message = message
    return this
  }

  setStack(stack: string): ErrorBuilder {
    this._error.stack = stack
    return this
  }

  setStackTrace(stacktrace: StackFrame[]): ErrorBuilder {
    this._error.stacktrace = stacktrace
    return this
  }

  setCode(code: number | string): ErrorBuilder {
    this._error.code = code
    return this
  }

  build(): MCMError {
    return this._error
  }

  buildAsRecordExceptionOptions(): RecordExceptionOptions {
    return this._error as RecordExceptionOptions
  }
}
