import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { ListType, TeamType } from '@mediacoach-ui-library/global'
import { SegmentModifier } from '../segmented-control/segmented-control.models'
import { parseStatic } from '@core/utils/statics.utils'
import { StaticOrientation } from '@core/enums/statics.enum'
import { Store } from '@ngrx/store'
import { TypedAction } from '@ngrx/store/src/models'
import { SelectionType } from '@shared/components/player-selection/player-selection.model'
import { playerDetailActions } from '@pages/player-detail/store/actions/player-detail.actions'
import { DemarcationPipe } from '@shared/pipes/demarcation.pipe'
import { PLAYER_DEMARCATION_FALLBACK_KEY, PLAYER_DEMARCATION_KEYS } from '@core/constants/player.constants'

@Component({
  selector: 'mcm-player-comparison',
  templateUrl: './player-comparison.component.html',
  styleUrls: ['./player-comparison.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlayerComparisonComponent {
  teams: { home: any; away: any }
  teamSegmentOptions = []
  selectedTeam = TeamType.Home
  selection: any
  SegmentModifier = SegmentModifier

  @Input() selectedPlayer
  @Input() title: string
  @Input() titleParams: { [key: string]: string }
  @Input() actionClose: () => TypedAction<any>
  @Input() handleComparison: ({ data, modalContext }) => TypedAction<any>

  @Input()
  set match(match) {
    if (match) {
      this.teams = {
        [TeamType.Home]: this._parseTeam(match.homeTeam, match.seasonId, match.competitionId),
        [TeamType.Away]: this._parseTeam(match.awayTeam, match.seasonId, match.competitionId)
      }
      this.teamSegmentOptions = [match.homeTeam, match.awayTeam].map((team, i) => ({
        id: i === 0 ? TeamType.Home : TeamType.Away,
        label: team.shortName || team.name,
        image: parseStatic(team.statics, StaticOrientation.Portrait)
      }))
    }
  }

  @Output() playerSelect = new EventEmitter<{ playerA: any; playerB: any }>()

  constructor(
    private readonly _store: Store,
    private readonly _demarcationPipe: DemarcationPipe<any>
  ) {}

  onTeamChange(team): void {
    this.selectedTeam = team
  }

  private _parseTeam(team, seasonId: string, competitionId: string): { [key: string]: unknown } {
    return {
      id: team.id,
      type: ListType.Players,
      groups: [
        {
          items: team.lineup.players.map(({ id, shirtNumber, playerPosition, ...player }) => ({
            isSelectable: true,
            imgUrl: parseStatic(player.statics, StaticOrientation.Portrait),
            name: player.formattedName,
            teamShield: parseStatic(team.statics, StaticOrientation.Portrait),
            teamName: team.name,
            playerId: id,
            teamId: team.id,
            position: this._demarcationPipe.transform(
              player,
              PLAYER_DEMARCATION_KEYS,
              PLAYER_DEMARCATION_FALLBACK_KEY,
              ' '
            ),
            playerPosition,
            competitionId,
            seasonId,
            shirtNumber,
            id
          }))
        }
      ]
    }
  }

  onClosed() {
    this._store.dispatch(this.actionClose())
  }

  onPlayerSelected(data) {
    this.playerSelect.emit(data)
    if (this.handleComparison) {
      this._store.dispatch(playerDetailActions.setToComparePlayer({ toComparePlayer: data.playerB }))
      this._store.dispatch(
        this.handleComparison({
          data: data.playerB,
          modalContext: SelectionType.PlayerSelection
        })
      )
    }
  }
}
