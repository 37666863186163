import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core'
import { HttpClient, HttpClientModule } from '@angular/common/http'

import es from '@angular/common/locales/es'
import en from '@angular/common/locales/en'
import { registerLocaleData } from '@angular/common'

import { RouteReuseStrategy } from '@angular/router'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { IonicModule, IonicRouteStrategy } from '@ionic/angular'

import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'

import { NetworkInterface } from '@awesome-cordova-plugins/network-interface/ngx'
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx'

import { AppComponent } from './app.component'
import { CoreModule } from '@core/core.module'
import { SharedModule } from '@shared/shared.module'
import { AppRoutingModule } from './app-routing.module'
import { MetricModule } from '@pages/metric/metric.module'
import { MCMErrorHandler } from '@core/errors/error-handler'
import { Smartlook } from '@awesome-cordova-plugins/smartlook/ngx'
import { OverlayModule } from '@angular/cdk/overlay'
import { UpdaterModule } from '@core/services/updater/updater.module'

registerLocaleData(es)
registerLocaleData(en)

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json')
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      mode: 'md'
    }),
    AppRoutingModule,
    CoreModule,
    OverlayModule,
    MetricModule,
    UpdaterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),
    SharedModule
  ],
  providers: [
    Smartlook,
    ScreenOrientation,
    NetworkInterface,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: MCMErrorHandler }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
