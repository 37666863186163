import { Store } from '@ngrx/store'
import { Injectable } from '@angular/core'
import { delay, filter } from 'rxjs/operators'
import { HubConnectionState } from '@microsoft/signalr'
import { fetchTopic, setSocketId, setSocketStatus } from '@core/store/actions/socket.actions'
import { selectSocketStatus } from '@core/store/selectors/socket.selectors'
import { SignalrService } from '@core/websockets/signalr/services/signalr.service'
import { RECONNECT_DELAY } from '@core/websockets/signalr/constants/signalr.constants'
import { ROOM_RECEIVE_MESSAGE } from '@sockets/constants/socket.constants'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { untilDestroyed } from '@ngneat/until-destroy'

@Injectable()
export class SocketService {
  constructor(
    private readonly _store: Store,
    private readonly _signal: SignalrService
  ) {
    this._signal
      .setupRoom(ROOM_RECEIVE_MESSAGE)
      .pipe(takeUntilDestroyed())
      .subscribe((hub) => {
        hub.onclose((error) => {
          this._signal.updateStatus(HubConnectionState.Disconnected, error)
          this._signal.reconnect().pipe(untilDestroyed(this)).subscribe()
        })
      })

    this._signal.message$.subscribe((socketMessage) => this._store.dispatch(fetchTopic({ socketMessage })))

    this._signal.statusChange$.subscribe(({ status }) => {
      this._store.dispatch(setSocketStatus({ status }))
      if (
        status !== HubConnectionState.Connected
      ) {
        console.warn('Socked is disconnected')
        this._signal
          .stopConnection()
          .pipe(delay(RECONNECT_DELAY))
          .subscribe(() => this.connect())
      }
    })
  }

  connect(): void {
    this._signal.startConnection().subscribe((hub) => {
      this._store.dispatch(setSocketId({ id: hub.connectionId }))
      this._store.dispatch(setSocketStatus({ status: hub.state }))
    })
  }

  onConnect() {
    return this._store.select(selectSocketStatus).pipe(filter((status) => status === HubConnectionState.Connected))
  }

  dispose(): void {
    this._signal.dispose()
  }
}
