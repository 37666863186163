import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  isDevMode,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core'
import {
  DEFAULT_STEP_TIME,
  MEDIA_ERR_NETWORK,
  REFRESH_TIME_UPDATE,
  VIDEOJS_SETTINGS
} from '../../constants/video.constants'
import { getMimetype, updateTimeWithFormat } from '../../utils/video.utils'
import { PLAYER_EVENTS } from '@shared/components/video-container/constants/video-events.constants'
import {
  HLSPlayer,
  HLSTech,
  StreamSource,
  VideoAnalyticsEvent,
  VideoJsComponent
} from '@shared/components/video-container/models/video.models'
import { retryable } from '@shared/decorators/retryable/retryable-decorator'

import { BackOffPolicy } from '@shared/decorators/retryable/enums/retryable.enums'
import { VideoEvent } from '@shared/components/video-container/enums/video.enum'
import { throttle } from '@core/utils/main.utils'

import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx'
import videojs from 'video.js'
import { debounce } from '@mediacoach-ui-library/global'
import 'jb-videojs-hls-quality-selector'
import { PlatformType } from '@core/models/models'
import { Capacitor } from '@capacitor/core'

@Component({
  selector: 'mcm-video',
  template: ` <video
    #player
    class="mcp-video video-js vjs-big-play-centered vjs-waiting"
    autoplay
    controls
    muted
    playsinline
    preload="none"></video>`,
  styleUrls: ['./video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoComponent implements AfterViewInit, OnDestroy {
  private _player: HLSPlayer
  private _mimeType: string
  private _source: string
  private _formatTimeFn: (...args: unknown[]) => void

  private get _seekBar(): VideoJsComponent {
    return this._player ? this._player.getDescendant('controlBar', 'progressControl', 'seekBar') : null
  }

  get source() {
    return this._source
  }

  @Input() set source(s: string) {
    this._handleSource(s)
  }

  @Input() set formatTimeFn(_formatTimeFn) {
    this._formatTimeFn = _formatTimeFn
    this._handleFormatTime()
  }

  get formatTimeFn() {
    return this._formatTimeFn
  }

  @Input() lang: string
  @Input() withCredentials: boolean
  @Input() streamTitle: string
  @Input() debug: boolean

  @Output() videoError = new EventEmitter<unknown>()
  @Output() analytics = new EventEmitter<VideoAnalyticsEvent>()
  @Output() time = new EventEmitter<number>()
  @Output() fullscreenChange = new EventEmitter<boolean>()
  @Output() playChange = new EventEmitter<boolean>()
  @Output() playerReady = new EventEmitter<void>()
  @Output() videoSeek = new EventEmitter<unknown>()

  @ViewChild('player', { static: true }) videoElement: ElementRef<HTMLElement>

  @HostBinding('class.no-quality-levels')
  get hasNoQualityLevels() {
    return this._player?.qualityLevels()?.levels_?.length < 2
  }

  constructor(private readonly _screenOrientation: ScreenOrientation) {}

  private _initializePlayer(): void {
    if (this.videoElement.nativeElement) {
      this._player = videojs(
        this.videoElement.nativeElement,
        {
          ...VIDEOJS_SETTINGS,
          language: this.lang
        },
        () => this._setupHLSPlugins()
      ) as HLSPlayer

      this._handleListeners()
      this._enableDebug(this.debug)

      if (this.source) {
        this._setSrc({ type: this._mimeType, src: this.source })
      }
    }
  }

  private _handleSource(source: string): void {
    this._source = source
    if (source) {
      this._mimeType = getMimetype(source)

      if (this._player) {
        this._setSrc({ type: this._mimeType, src: source })
      }
    }
  }

  private _handleListeners(): void {
    this._setupEventListeners(
      'on',
      [VideoEvent.Play, VideoEvent.Ended, VideoEvent.Pause, VideoEvent.Fullscreenchange],
      (event) => {
        this.analytics.emit({ event })
      }
    )
    this._setupEventListeners(
      'on',
      [VideoEvent.Loadeddata, VideoEvent.Error, VideoEvent.Timeupdate],
      (ev) => {
        this.time.emit(this._getTime(ev))
      },
      { delay: REFRESH_TIME_UPDATE, eventName: VideoEvent.Timeupdate }
    )
    this._player.on(VideoEvent.Fullscreenchange, () => {
      this.fullscreenChange.emit(this._player.isFullscreen())
      if (Capacitor.getPlatform() === PlatformType.Android) {
        this._screenOrientation.unlock()
        if (this._player.isFullscreen()) {
          this._screenOrientation
            .lock(this._screenOrientation.ORIENTATIONS.LANDSCAPE)
            .catch((error) => console.log(error))
        } else {
          this._screenOrientation
            .lock(this._screenOrientation.ORIENTATIONS.PORTRAIT)
            .catch((error) => console.log(error))
        }
      }
    })
    this._setupEventListeners('on', [VideoEvent.Waiting, VideoEvent.Pause, VideoEvent.Playing], (ev) => {
      this.playChange.emit(ev === VideoEvent.Playing)
    })
    this._player.on(VideoEvent.Canplay, () => this.playerReady.emit())
  }

  private _getTime(ev: VideoEvent) {
    let _time = this._player.currentTime() + this._player.remainingTime()
    switch (ev) {
      case VideoEvent.Error:
        _time = null
        break
      case VideoEvent.Timeupdate:
        _time = this._player.liveTracker.liveCurrentTime()
        break
      case VideoEvent.Loadeddata:
        _time = isFinite(_time) ? _time : this._player.liveTracker.liveCurrentTime()
        break
    }
    return _time
  }

  @debounce(0)
  @retryable({
    maxAttempts: 3,
    backOffPolicy: BackOffPolicy.ExponentialBackOffPolicy,
    backOff: 1000,
    exponentialOption: { maxInterval: 4000, multiplier: 3 },
    doRetry: (e: { code: number; message: string; status?: number }) => e.code === MEDIA_ERR_NETWORK && e.status !== 404
  })
  private _setSrc(source: StreamSource): Promise<unknown | Error> {
    if (source) {
      return new Promise((resolve, reject) => {
        this._player.pause()
        this._player.src({
          ...source,
          withCredentials: this.withCredentials
        } as any)
        this._handlePlayerEvents(resolve, reject)
        this._handleFormatTime()
        this._player.load()
      })
    }
  }

  private _setupEventListeners(
    handlerType: 'on' | 'one' | 'off',
    events: string[],
    callback?: (...args) => void,
    ...throttleTime: { delay; eventName }[]
  ): void {
    events.forEach((ev) =>
      this._player[handlerType](ev, (eventData) => {
        const throttled = (throttleTime || []).find((item) => ev === item.eventName)
        if (throttled) {
          throttle(() => callback(ev, eventData), throttled.delay)
        } else {
          callback(ev, eventData)
        }
      })
    )
  }

  /**
   * Attach a listener to almost every player event.
   * It should be removed once we're sure that this component works as intended
   *
   * @see {link PLAYER_EVENTS}
   * @param enable {boolean}
   * @default false
   * @private
   */
  private _enableDebug(enable: boolean): void {
    if (isDevMode() && enable) {
      PLAYER_EVENTS.forEach((ev) =>
        this._player.on(ev, (data) => {
          console.log(`%c[VIDEO.JS] ${ev}`, 'color: orange', data)
        })
      )
    }
  }

  private _setupHLSPlugins(): void {
    ;(this._player.tech({ IWillNotUseThisInPlugins: true }) as HLSTech).hls = {}
    this._player.hlsQualitySelector({
      displayCurrentQuality: true
    })
  }

  private _handlePlayerEvents(resolve: (...args: unknown[]) => void, reject: (...args: unknown[]) => void): void {
    this._player.off(VideoEvent.Error, () => {})
    this._player.on(VideoEvent.Error, () => {
      const err = this._player.error()
      this.videoError.emit(err)
      reject(err)
    })
    this._player.one(VideoEvent.Canplay, () => {
      resolve(this._player.play())
    })
  }

  private _handleFormatTime(): void {
    if (this._player && this.formatTimeFn && this._seekBar) {
      if (this._seekBar.getDescendant('mouseTimeDisplay')) {
        this._seekBar.getDescendant('mouseTimeDisplay', 'timeTooltip').updateTime = updateTimeWithFormat(
          this.formatTimeFn
        )
      }
      if (this._seekBar.getDescendant('bar')) {
        this._seekBar.getDescendant('bar', 'timeTooltip').updateTime = updateTimeWithFormat(this.formatTimeFn)
      }
    }
  }

  playAt(time: number): void {
    this._player.currentTime(time)
    this._player.play()
  }

  playFrom(seconds: number): void {
    this.playAt(this._player.currentTime() + (seconds || DEFAULT_STEP_TIME))
  }

  getCurrentTime(): number {
    return this._player.currentTime()
  }

  isFullscreen(): boolean {
    return this._player.isFullscreen()
  }

  setPlayerTime(time: number): void {
    this._player.currentTime(time)
  }

  seekToLiveEdge(): void {
    this._player.liveTracker.seekToLiveEdge()
  }

  showControls(visibility: boolean) {
    this._player.userActive(visibility)
  }

  ngAfterViewInit(): void {
    this._initializePlayer()
  }

  ngOnDestroy(): void {
    if (this._player) {
      this._player.one(VideoEvent.Pause, () => this._player.dispose())
      this._player.pause()
    }
  }
}
