import { ChangeDetectionStrategy, Component } from '@angular/core'
import { TypedAction } from '@ngrx/store/src/models'
import { Store } from '@ngrx/store'

@Component({
  selector: 'mcm-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarningModalComponent {
  title: string
  body: string
  actionClose: () => TypedAction<any>
  constructor(private readonly _store: Store) {}

  onClose() {
    this._store.dispatch(this.actionClose())
  }
}
