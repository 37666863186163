import { ErrorHandler, Injectable } from '@angular/core'
import { recordClientException } from './utils/error.utils'

@Injectable()
export class MCMErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    console.error(error)
    recordClientException(error)
  }
}
